:root {
  --sidenav-btn: var(--btn-secondary);
  --sidenav-btn-hover: var(--btn-secondary-hover);
  --sidenav-btn-text: var(--color-tertiary);
  --sidenav-btn-text-hover: var(--color-tertiary);
  // --sidenav-popup-bg: var(--color-primary-1);
  --sidenav-popup-bg: var(--bg-primary);
}

.side-navigation {
  position: fixed;
  inset: auto 40px 40px auto;
  z-index: 500;

  @include media-breakpoint-down(md) {
    inset: auto 20px 20px auto;
  }

  @include media-breakpoint-down(sm) {
    inset: auto 0 0 0;
  }

  &__popup {
    position: absolute;
    inset: auto 0 50px auto;
    min-width: 320px;
    padding: 3rem;
    background-color: var(--sidenav-popup-bg);
    border-radius: 10px;
    box-shadow: 0 5px 40px rgba(black, 0.2);
    visibility: hidden;
    opacity: 0;
    z-index: 5;

    &::after {
      top: 100%;
      right: 30px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: var(--sidenav-popup-bg);
      border-width: 10px;
      margin-left: -10px;
    }

    @include media-breakpoint-down(sm) {
      inset: auto 0 30px 0;
      padding: 2rem;
      border-radius: 0;

      &::after {
        display: none;
      }
    }
  }

  &__title {
    margin-block-end: 1rem;
    font-size: 18px;
    font-weight: normal;
    color: rgba(white, 0.75);
    text-transform: uppercase;
  }

  &__item {
    &:not(:last-child) {
      margin-block-end: 0.5rem;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__link {
    position: relative;
    font-size: 14px;
    color: rgba(white, 0.5);
    text-decoration: none;
    @include effect();

    &:hover {
      color: rgba(white, 1);
      text-underline-position: above;
    }

    &.current {
      color: white;

      &::before {
        content: "•";
        position: absolute;
        top: -0.15rem;
        left: -1rem;
        color: var(--color-primary);
        @include effect();
      }
    }
  }

  &__button {
    position: relative;
    cursor: pointer;
    display: block;
    max-width: 600px;
    padding-inline: 2rem;
    min-width: 50px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: var(--sidenav-btn);
    border: 0;
    border-radius: 50vh;
    box-shadow: 0 5px 10px rgba(black, 0.2);
    @include effect();
    z-index: 10;

    &:hover {
      background-color: var(--sidenav-btn-hover);
      box-shadow: 0 5px 30px rgba(black, 0.2);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      border-radius: 0;
    }
  }
}

.side-control {
  $this: &;
  position: fixed;
  inset: auto 40px 40px auto;
  display: flex;
  transform: translateX(70px);
  z-index: 500;
  @include effect();

  @include media-breakpoint-down(sm) {
    inset: auto 0 0 auto;
    transform: translateX(50px);
  }

  &__button-back,
  &__button-top {
    cursor: pointer;
    position: relative;
    display: grid;
    place-items: center;
    width: 60px;
    height: 60px;
    background-color: var(--sidenav-btn);
    border: 0;
    border-radius: 50vh;
    box-shadow: 0 5px 10px rgba(black, 0.2);
    @include effect();

    &::after {
      content: "";
      position: relative;
      width: 20px;
      height: 20px;
      background-color: white;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: cover;
    }

    &:not(:first-child) {
      margin-inline-start: 10px;
    }

    &:hover {
      background-color: var(--sidenav-btn-hover);
      box-shadow: 0 5px 30px rgba(black, 0.2);
    }

    @include media-breakpoint-down(sm) {
      width: 50px;
      height: 50px;
      border-radius: 0;
      box-shadow: none;

      &:not(:first-child) {
        margin-inline-start: 1px;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__button-back {
    &::after {
      mask-image: url("../img/icon/icon-arrow-left.svg");
    }
  }

  &__button-top {
    pointer-events: none;
    opacity: 0;

    &::after {
      mask-image: url("../img/icon/icon-arrow-top.svg");
    }
  }

  &.show {
    transform: translateX(0);

    #{$this}__button-back,
    #{$this}__button-top {
      pointer-events: auto;
      opacity: 1;
    }
  }
}
