blockquote {
    font-weight: bold;
    padding: 0;
    margin: 0 0 1rem 0;
}

.blockquote {
    display: flex;
    gap: 30px;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &--figure {
        flex-shrink: 0;
        max-width: 320px;

        @include media-breakpoint-down(lg) {
            max-width: 240px;
        }
    }

    &--content {
        position: relative;

        &::before {
            content: "";
            position: relative;
            display: inline-block;
            width: 3rem;
            height: 3rem;
            background-image: url('../img/icon/icon-testimonial.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 3rem;
        }
        
    }

    &--text {
        @include fluid-type($min_width, $max_width, 20px, 32px);
        line-height: 1.4;
        font-weight: 600;
        font-style: italic;
        color: var(--color-green-3);
        margin-block-end: .5rem;
    }

    &--text-sm {
        @include fluid-type($min_width, $max_width, 20px, 24px);
        line-height: 1.4;
        font-weight: 600;
        font-style: italic;
        color: var(--color-green-3);
        margin-block-end: .5rem;
    }

    &--info {
        font-size: 16px;
        font-weight: 600;
    }
}