.table-default {
	overflow-x: auto;

	table {
		width: 100%;
		padding: 0;
		margin: 0;
        font-size: 16px;
        border-collapse: collapse;
	}

	tr {
		width: 100%;
        @include effect();
	}

	td,
	th {
		width: 100%;
		text-align: left;
		min-width: 150px;
		padding: 1rem;

        &:first-child {
            padding-inline-start: 0.5rem;

			&.indent {
				padding-inline-start: 1.5rem;

			}
        }

		@include media-breakpoint-down(sm) {
			padding: .5rem;
		}
	}

	thead {
		tr {
			border-bottom: 2px solid var(--text);
			&.noborder {
				border: 0 none;
			}
		}
		th {
            vertical-align:bottom;

            span {
                font-size: 18px;
                color: var(--color-green-3)
            }
		}
	}

	tbody {
		tr {
            border-bottom: 1px solid var(--border);

            &:hover {
                background-color: var(--color-green-table-10);
            }
			&.noborder {
				border: 0 none;
			}
		}
		td {
		}
	}

    &__highlight {
        background-color: var(--color-green-table-10);
    }

    &__title {
        font-size: 24px;
    }

    &__meta {
        min-width: 100px !important;
        text-align: right !important;
    }
}
