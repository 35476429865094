.counter-grid {
  $this: &;
  display: grid;
  grid-template-columns: repeat(4, max(0, 1fr));
  gap: 20px;

  @include media-breakpoint-down(xxl) {
    grid-template-columns: repeat(3, max(0, 1fr));
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, max(0, 1fr));
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, max(0, 1fr));
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: var(--bg-light);
    text-align: center;
    padding-block: 2rem;
    height: 100%;

    @include media-breakpoint-down(md) {
      aspect-ratio: inherit;
    }

    &[data-bg="border"] {
      background-color: white;
      border: 2px var(--color-green-3) solid;
    }

    &[data-bg="white"] {
      background-color: white;
    }

    &[data-bg="primary"] {
      background-color: var(--bg-primary);
    }

    &[data-bg="secondary"] {
      background-color: var(--bg-secondary);
    }

    &[data-bg="title"] {
      justify-content: center;
      background-color: var(--bg-secondary);
      padding: 2rem;

      h2 {
        margin: 0;
      }

      @include media-breakpoint-down(lg) {
        order: -1;
      }
    }

    &[data-bg="dark"] {
      background-color: var(--bg-dark);
      color: white;
    }

    &[data-bg="light"] {
      background-color: var(--bg-light);
    }

    &[data-bg="primary"],
    &[data-bg="dark"] {
      #{$this}__number {
        --title: white;
      }

      #{$this}__text {
        --text: white;
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: 100%;
    max-width: 80px;
    height: auto;
    max-height: 80px;
    height: auto;
    margin-block-end: 1rem;
    aspect-ratio: 1/1;
    object-fit: contain;
    object-position: center;
  }

  &__number {
    margin-block-end: 0.5rem;
    margin: 0 0 0rem 0;
    padding: 0;
    @include fluid-type($min_width, $max_width, 54px, 46px);
    font-weight: 600;
    line-height: 1.3;
    color: var(--color-green-3);
  }

  &__text {
    font-size: 16px;
  }

  &[data-mode="single"] {
    display: inline-block;

    #{$this}__number,
    #{$this}__number-group {
        margin: 0 0 0 0;
        padding: 0;
        @include fluid-type($min_width, $max_width, 60px, 80px);
        font-weight: 600;
        line-height: 1.3;
        color: var(--color-green-3);

        small {
            font-size: .5em;
        }

        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          height: 50%;
          bottom: 10%;
          background-color: var(--color-primary-5);
          z-index: -1;
          @include effect();
        }

    }

    #{$this}__text {
        font-size: 14px;
    }
}
}